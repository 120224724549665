/**
 * The collection of i18n strings.
 *
 * @since 3.0.0
 */
export const I18N = {
  prev  : 'Previous slide',
  next  : 'Next slide',
  first : 'Go to first slide',
  last  : 'Go to last slide',
  slideX: 'Go to slide %s',
  pageX : 'Go to page %s',
  play  : 'Start autoplay',
  pause : 'Pause autoplay',
};
